
import { defineComponent, onMounted, ref, useContext, useRoute, useRouter, useAsync } from "@nuxtjs/composition-api";
import { useBa } from "@/stores/use-ba";
import { useBaPayment } from "@/stores/use-ba-payment";
import { useLoading } from "@/stores/use-loading";
import { useConstants } from "@/stores/use-constants";
import { provideStore, getStore } from "@/compositions/store";
import "@/assets/css/common.css";
import { PaymentMasterApi } from "@/api/payment/payment-master-api";
import CFlex from "@/components/ui-parts/CFlex.vue";
import CListItemContent from "@/components/ui-parts/CListItemContent.vue";

type Menu = {
  type?: string;
  position?: string;
  to?: string;
  pathList?: RegExp[];
  icon?: string;
  activeIcon?: string;
  title?: string;
  isReceive?: boolean;
  receiveDisplay?: boolean;
  onlyOwner?: boolean;
  showCondition?: () => boolean;
  subItems?: Menu[];
};

export default defineComponent({
  middleware: ["auth-general"],
  components: {
    CFlex,
    CListItemContent
  },
  setup() {
    /**
     * providers
     */
    provideStore();

    /**
     * Composables
     */
    const { onResize, isMobile, mobileWidth } = getStore();
    const { state: baPaymentState } = useBaPayment();
    const { showLoading, hideLoading } = useLoading();
    const { initialize: initConstants } = useConstants();
    const { state: baState } = useBa();
    const context = useContext();
    const router = useRouter();
    const route = useRoute();

    /**
     * Data
     */
    const drawer = ref<boolean>(false);
    const isShownSubMenu = ref<boolean>(false);
    // クッキーポリシーメニューの表示非表示(暫定)
    const isViewcookiePolicy = ref<boolean>(false);
    const isMount = ref(false);

    const menuItems: Menu[] = [
      {
        icon: "mdi-home-outline",
        activeIcon: "mdi-home",
        title: "TOP",
        to: "/top",
        pathList: [],
        onlyOwner: false
      },
      { type: "divider" },
      {
        type: "subheader",
        title: "発行機能"
      },
      {
        icon: "mdi-calculator-variant-outline",
        activeIcon: "mdi-calculator-variant",
        title: "見積書",
        to: "/estimates/list",
        pathList: [/^\/estimates(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-truck-outline",
        activeIcon: "mdi-truck",
        title: "納品書",
        to: "/delivery-slips/list",
        pathList: [/^\/delivery-slips(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-receipt-text-outline",
        activeIcon: "mdi-receipt-text",
        title: "請求書",
        to: "/invoices/list",
        pathList: [/^\/invoices(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-receipt-text-check-outline",
        activeIcon: "mdi-receipt-text-check",
        title: "領収書",
        to: "/receipts",
        pathList: [/^\/receipts(\/.*)?$/],
        onlyOwner: false
      },
      { type: "divider" },
      {
        type: "subheader",
        title: "受取機能",
        receiveDisplay: true,
        isReceive: true
      },
      {
        isReceive: true,
        icon: "mdi-receipt-text-outline",
        activeIcon: "mdi-receipt-text",
        title: "請求書",
        to: "/receive/invoices",
        pathList: [/^\/receive\/invoices(\/.*)?$/],
        onlyOwner: false
      },
      {
        isReceive: true,
        icon: "mdi-receipt-text-check-outline",
        activeIcon: "mdi-receipt-text-check",
        title: "領収書",
        to: "/receive/receipts",
        pathList: [/^\/receive\/receipts(\/.*)?$/],
        onlyOwner: false
      },
      {
        isReceive: true,
        icon: "mdi-text-box-multiple-outline",
        activeIcon: "mdi-text-box-multiple",
        title: "その他帳票",
        to: "/receive/others",
        pathList: [/^\/receive\/others(\/.*)?$/],
        onlyOwner: false
      },
      {
        type: "divider",
        isReceive: true
      },
      {
        icon: "mdi-chart-line",
        activeIcon: "mdi-chart-line",
        title: "レポート",
        to: "/reports/list",
        pathList: [/^\/reports(\/.*)?$/],
        onlyOwner: false
      },
      {
        icon: "mdi-cog-outline",
        title: "設定",
        to: "#",
        subItems: [
          {
            icon: "mdi-shield-account-outline",
            activeIcon: "mdi-shield-account",
            title: "アカウント",
            to: "/accounts/settings",
            onlyOwner: true,
            pathList: [
              /^\/terms(\/.*)?$/,
              /^\/accounts\/settings(\/.*)?$/,
              /^\/accounts\/register\/addplan(\/.*)?$/,
              /^\/accounts\/register\/changeplan(\/.*)?$/,
              /^\/accounts\/change(\/.*)?$/
            ]
          },
          {
            icon: "mdi-account-cog-outline",
            activeIcon: "mdi-account-cog",
            title: "ユーザー管理",
            to: "/accounts/user/list",
            pathList: [/^\/accounts\/user(\/.*)?$/],
            onlyOwner: true
          },
          {
            icon: "mdi-shield-alert-outline",
            activeIcon: "mdi-shield-alert",
            title: "自社情報",
            to: "/companies/list",
            pathList: [/^\/companies(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-office-building-outline",
            activeIcon: "mdi-office-building",
            title: "取引先情報",
            to: "/customers/list",
            pathList: [/^\/customers(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "品目情報",
            to: "/items/list",
            pathList: [/^\/items(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "帳票設定",
            to: "/settings/documents",
            pathList: [/^\/settings(\/.*)?$/],
            onlyOwner: false
          },
          {
            icon: "mdi-list-box-outline",
            activeIcon: "mdi-list-box",
            title: "事務処理規程",
            to: "/office-process-rule-templates",
            pathList: [/^\/office-process-rule-templates(\/.*)?$/],
            onlyOwner: false
          }
        ]
      },
      // 下部メニュー
      {
        icon: "mdi-help-circle-outline",
        activeIcon: "mdi-help-circle",
        title: "サポート",
        to: "/support/faq",
        pathList: [/^\/support(\/.*)?$/],
        position: "bottom"
      }
      // クッキーポリシーメニューの表示非表示(暫定)
      // {
      //   icon: "mdi-key-outline",
      //   activeIcon: "mdi-key",
      //   title: "クッキーポリシー",
      //   to: "/cookie-policy",
      //   position: "bottom"
      // }
    ];

    /**
     * Methods
     */
    // 左ナビが現在のページか判断
    const isActive = (menu: Menu | string, isSubmenu = false): boolean => {
      const currentPath = route.value.path;

      // クッキーポリシーメニューの表示非表示(暫定)
      if (typeof menu === "string") {
        return currentPath === menu;
      }

      // 指定した子ページのパスと合致するかチェック
      if (menu.pathList.length) {
        for (let index = 0; index < menu.pathList.length; index++) {
          if (menu.pathList[index].test(currentPath)) {
            if (isSubmenu) {
              isShownSubMenu.value = true;
            }
            return true;
          }
        }
      }

      // 遷移先と合致するかチェック
      return currentPath === menu.to;
    };

    // エラーページかどうかの判定
    const isErrorPage = (): boolean => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return context._errored;
    };

    // 現在のパスと異なる場合のみ画面遷移
    const clickHandler = (event: any, path: string): void => {
      event.preventDefault();
      event.stopPropagation();
      if (route.value.path !== path) {
        router.push(path);
      }
    };

    /** Api */
    const paymentMasterApi = new PaymentMasterApi();

    const getMasterServiceTermsCookie = () => {
      showLoading();

      return paymentMasterApi
        .getMasterServiceTermsCookie()
        .then((data) => {
          if (data.data[0]) {
            isViewcookiePolicy.value = true;
          }
        })
        .catch(() => {
          isViewcookiePolicy.value = false;
        })
        .finally(() => {
          hideLoading();
        });
    };

    /**
     * Created
     */
    (async () => {
      await initConstants();

      isMount.value = true;
    })();

    useAsync(async () => {
      await getMasterServiceTermsCookie();
    });

    /**
     * onMounted
     */
    onMounted(() => {
      onResize();

      // pcの場合は左メニューを最初から表示する
      if (!isMobile.value) {
        drawer.value = true;
      }

      if (isErrorPage()) {
        isMobile.value = true;
      }
    });

    return {
      baState,
      baPaymentState,
      drawer,
      menuItems,
      isShownSubMenu,
      onResize,
      mobileWidth,
      isMobile,
      isErrorPage,
      clickHandler,
      isViewcookiePolicy, // クッキーポリシーメニューの表示非表示(暫定)
      isActive,
      isMount
    };
  },
  head() {
    return {
      title: ""
    };
  }
});
