
import { defineComponent, computed } from "@nuxtjs/composition-api";
import { useBaPayment } from "@/stores/use-ba-payment";

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup() {
    /**
     * Composables
     */
    const { state: baPaymentState } = useBaPayment();

    /**
     * Computed
     */
    const contactFormUrl = computed((): string => {
      return baPaymentState.isExistPaymentUser
        ? "https://docs.google.com/forms/d/e/1FAIpQLSfy627YBZEUCcYJJURaPx5j4Hf_7bdC2z8DyIPuRa3ZNWTZcQ/viewform?usp=sf_link"
        : "https://docs.google.com/forms/d/e/1FAIpQLScl37-MFonv8IpXW1G_dOnx03AH1iBjtlGPdqeTC4BR4N3gVA/viewform?usp=sf_link";
    });

    return {
      contactFormUrl
    };
  }
});
