import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { IJwtDecodeBaUser } from "@/types/ba";
import { CHECK_REGISTERED_NO_RESULT_STATUS } from "~/utils/constants";

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(isBetween);

/**
 * decodeJwt
 */
export const decodeJwt = (): IJwtDecodeBaUser | null => {
  const idToken: string = localStorage.getItem("auth._token.bamidp") || "";
  if (!idToken || idToken === "false") {
    return null;
  }

  const base64Url = idToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const result = JSON.parse(decodeURIComponent(escape(window.atob(base64))));

  return {
    ba_company_name: result.ba_company_name,
    ba_user_bank_code: result.ba_user_bank_code,
    ba_company_unique_code: result.ba_company_unique_code,
    ba_user_email: result.ba_user_email,
    ba_user_name: result.ba_user_name,
    ba_user_type: result.ba_user_type,
    ba_user_unique_code: result.ba_user_unique_code
  };
};

/**
 * 利用期限チェック
 */
export const isUseValid = (start: string, end: string): boolean => {
  const today = dayjs();
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  if (today.isBetween(startDate, endDate)) {
    return true;
  }
  return false;
};

/**
 * 課金予定終了日時チェック
 */
export const isPaymentEndDateValid = (end_date: string): boolean => {
  const today = dayjs(dayjs().format("YYYY-MM-DD"));
  const withdrawalDate = dayjs(end_date);
  return today.isSame(withdrawalDate) || today.isBefore(withdrawalDate);
};

export const calcTaxIncludeAmount = (amount: number): number => amount * 1.1;

/**
 * APIから返却された適格請求書発行事業者の公表情報と入力された取引先名が一致するかを判定する
 */
export const toCheckRegisteredNoResultStatus = (companyName: string, customerName: string) => {
  if (!companyName) return CHECK_REGISTERED_NO_RESULT_STATUS.NOT_FOUND;

  if (companyName === customerName) return CHECK_REGISTERED_NO_RESULT_STATUS.MATCHED;

  return CHECK_REGISTERED_NO_RESULT_STATUS.UNMATCHED;
};
