import { NuxtAppOptions } from "@nuxt/types";
import { useBa } from "@/stores/use-ba";

export default async ({ route, $auth, $sentry, redirect, error }: NuxtAppOptions): Promise<void> => {
  const { state: baState, loadBaUser } = useBa();

  /**
   * エラー発生時、Sentryにイベントを時の共通データをセットする
   *
   * 仮にロギング処理内でエラーが発生しても、無視して通常処理を続行させるようにしています。
   */
  const setDimensionToSentry = () => {
    try {
      $sentry.setUser({
        ba_user_bank_code: baState.user.ba_user_bank_code,
        ba_user_bank_name: baState.user.ba_user_bank_name,
        ba_user_type: baState.user.ba_user_type,
        ba_user_unique_code: baState.user.ba_user_unique_code,
        user_kind: "admin"
      });
    } catch {}
  };

  if (!$auth.loggedIn) {
    // bamidpにログインしていない場合はエラー
    error({ statusCode: 401 });
  }

  // TODO: <GlobalNotice />をapp.vueに配置できたら、useNoticeを使用して非表示
  // hideNotice();

  // アクセストークンでユーザ情報（銀行名など）取得
  await loadBaUser();

  if (!baState.user || !baState.user.is_credit_card) {
    // ユーザ情報が取得できていないまたはBA側でクレジットカード登録されていない場合
    error({ statusCode: 401 });
  }

  // エラー発生時にSentryへ常に送信する基本情報をセット
  setDimensionToSentry();

  if (baState.isCompanyOwner || baState.isCompanyUser) {
    // 企業側アカウントのときは企業側画面のトップにリダイレクト
    redirect("/top");
  }

  // 金融機関設定はZBAのシステム管理者のみアクセス可能
  if (route.path.includes("bank-setting") && !baState.isSystemAdminZba) {
    error({ statusCode: 403 });
  }

  if (!baState.isSystemAdmin && !baState.isBankAdmin && !baState.isBranchAdmin && !baState.isBranchUser) {
    // システム管理者、金融機関（事務局）、金融機関（支店）以外ならエラー
    error({ statusCode: 401 });
  }
};
