import { reactive } from "@nuxtjs/composition-api";
import { defineStore } from "pinia";
import { decodeJwt } from "@/utils";
import { getAccessToken, getBaConfig, getUserInfo } from "@/api/user";
import { Bank, IBaUser } from "~/types/ba";
import { BA_USER_TYPE, ZBA_BANK_CODE } from "@/utils/constants";

const emptyUser: IBaUser = {
  ba_company_name: "",
  ba_company_type: "",
  ba_company_unique_code: "",
  ba_user_bank_code: "",
  ba_user_bank_name: "",
  ba_user_bb_code: "",
  ba_user_bb_name: "",
  ba_user_email: "",
  ba_user_first_name: "",
  ba_user_first_name_kana: "",
  ba_user_last_name: "",
  ba_user_last_name_kana: "",
  ba_user_name: "",
  ba_user_type: "",
  ba_user_unique_code: "",
  ba_user_bb_unique_code: "",
  is_credit_card: false
};

const emptyBank: Bank = {
  ba_name: "",
  // NOTE: 発行型については、BAMのON/OFFに応じた表示の出し分けの影響範囲が大きいため、対応方針を検討後実装予定
  // is_enable_payment_issue: true,
  is_enable_payment_receive: true
};

export const useBa = defineStore("baStore", () => {
  /**
   * Data
   */
  const state = reactive({
    user: structuredClone(emptyUser),
    bank: structuredClone(emptyBank),
    isCompanyOwner: false,
    isCompanyUser: false,
    isSystemAdmin: false,
    isBankAdmin: false,
    isBranchAdmin: false,
    isBranchUser: false,
    isSystemAdminZba: false,
    isSystemAdminOrBankAdmin: false,
    isBranchAdminOrBranchUser: false,
    isAdminUser: false
  });

  /**
   * Methods
   */
  const _initStateUser = (user: IBaUser) => {
    const adminUserTypes = [
      BA_USER_TYPE.SYSTEM_ADMIN, // システム管理者
      BA_USER_TYPE.BANK_ADMIN, // 金融機関（事務局）
      BA_USER_TYPE.BRANCH_ADMIN, // 金融機関（支店）
      BA_USER_TYPE.BRANCH_USER // 金融機関（支店）ユーザー
    ];

    state.user = user;
    state.isCompanyOwner = user.ba_user_type === BA_USER_TYPE.COMPANY_OWNER;
    state.isCompanyUser = user.ba_user_type === BA_USER_TYPE.COMPANY_USER;
    state.isSystemAdmin = user.ba_user_type === BA_USER_TYPE.SYSTEM_ADMIN;
    state.isBankAdmin = user.ba_user_type === BA_USER_TYPE.BANK_ADMIN;
    state.isBranchAdmin = user.ba_user_type === BA_USER_TYPE.BRANCH_ADMIN;
    state.isBranchUser = user.ba_user_type === BA_USER_TYPE.BRANCH_USER;
    state.isSystemAdminZba = state.isSystemAdmin && user.ba_user_bank_code === ZBA_BANK_CODE;
    state.isSystemAdminOrBankAdmin = state.isSystemAdmin || state.isBankAdmin;
    state.isBranchAdminOrBranchUser = state.isBranchAdmin || state.isBranchUser;
    state.isAdminUser = adminUserTypes.includes(user.ba_user_type);
  };

  const loadBaUser = async () => {
    const decodeUserInfo = decodeJwt();
    if (!decodeUserInfo) {
      return false;
    }

    // ローカルストレージにアクセストークンが存在しなかったら取得する
    if (!localStorage.getItem("auth.access-token")) {
      const accessTokenResult = await getAccessToken();
      localStorage.setItem("auth.access-token", accessTokenResult.data.result.access_token);
    }

    // userinfo取得
    const userInfoResult: any = await getUserInfo(
      decodeUserInfo?.ba_user_bank_code,
      localStorage.getItem("auth.access-token") as string
    );

    const config: any = await getBaConfig();
    const isCreditCard = !(
      config.data.result.config.check_payment_method_filled && !config.data.result.config.is_payment_method_filled
    );

    const baUserData = {
      ba_company_name: userInfoResult.data.ba_company_name ?? "",
      ba_company_type: userInfoResult.data.ba_company_type ?? "",
      ba_company_unique_code: userInfoResult.data.ba_company_unique_code ?? "",
      ba_user_bank_code: userInfoResult.data.ba_user_bank_code ?? "",
      ba_user_bank_name: userInfoResult.data.ba_user_bank_name ?? "",
      ba_user_bb_code: userInfoResult.data.ba_user_bb_code ?? "",
      ba_user_bb_name: userInfoResult.data.ba_user_bb_name ?? "",
      ba_user_email: userInfoResult.data.ba_user_email ?? "",
      ba_user_first_name: userInfoResult.data.ba_user_first_name ?? "",
      ba_user_first_name_kana: userInfoResult.data.ba_user_first_name_kana ?? "",
      ba_user_last_name: userInfoResult.data.ba_user_last_name ?? "",
      ba_user_last_name_kana: userInfoResult.data.ba_user_last_name_kana ?? "",
      ba_user_name: userInfoResult.data.ba_user_name ?? "",
      ba_user_type: userInfoResult.data.ba_user_type ?? "",
      ba_user_unique_code: userInfoResult.data.ba_user_unique_code ?? "",
      ba_user_bb_unique_code: userInfoResult.data.ba_user_bb_unique_code ?? "",
      is_credit_card: isCreditCard
    };

    const bankData = {
      ba_name: config.data.result.config.bank.ba_name ?? "",
      is_enable_payment_receive: config.data.result.config.enable_payment_receive ?? false // BAMからフラグが渡ってこない場合はfalseとする
    };

    return Promise.all([baUserData, bankData]).then(([user, bank]) => {
      if (user) {
        if (!sessionStorage.getItem("payload")) {
          sessionStorage.setItem("payload", JSON.stringify(user));
        }

        _initStateUser(user);
      }

      if (bank) {
        state.bank = bank;
      }

      return true;
    });
  };

  return {
    state,
    loadBaUser
  };
});
