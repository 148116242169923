import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AsyncData } from "@/api/types";
import { useBa } from "@/stores/use-ba";
import { MAINTENANCE_RESPONSE } from "@/utils/constants";

class ApiClient {
  // -------------------------------------------------------
  // Methods
  // -------------------------------------------------------
  /**
   * Get Request
   */
  get<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const query = data || null;

    return this.request(url, "GET", responseType, null, query) as any;
  }

  /**
   * Post Request
   */
  post<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json",
    timeout?: number
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "POST", responseType, body, null, timeout) as any;
  }

  /**
   * Patch Request
   */
  patch<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "PATCH", responseType, body, null) as any;
  }

  /**
   * Put Request
   */
  put<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "PUT", responseType, body, null) as any;
  }

  /**
   * Delete Request
   */
  delete<T = any, R = AsyncData<T>>(
    url: string,
    data?: Record<string, any> | null,
    responseType: "json" | "blob" = "json"
  ): Promise<R> {
    const body = data || null;

    return this.request(url, "DELETE", responseType, body, null) as any;
  }

  // -------------------------------------------------------
  // Private Methods
  // -------------------------------------------------------
  /**
   * HTTPリクエスト
   */
  private async request(
    url: string,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    responseType: "json" | "blob",
    body: Record<string, string> | null,
    query: Record<string, string> | null,
    timeout?: number
  ) {
    try {
      const { state: baState } = useBa();
      const apiUrl = process.env.API_URL;
      const token = localStorage.getItem("auth._token.bamidp") || "";

      // ロギング用の共通データをヘッダーにセット
      // TODO: 3系アップデート後document, windowを使用しないようリファクタ
      const path = `${window.location.pathname}${window.location.search}`;
      const screenName = document.title.split("｜")[0].trimEnd() || path ? path : "-";

      return await axios({
        method,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: token,
          "X-Amzn-Trace-Id": "Root=" + uuidv4(),
          "X-Ba-Bank-Name": encodeURI(baState.user.ba_user_bank_name),
          "X-Ba-Screen-Name": encodeURI(screenName),
          "X-Ba-Bank-Code": baState.user.ba_user_bank_code
        },
        data: body,
        params: query,
        responseType,
        timeout
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          const isMaintenance =
            error.response?.status === MAINTENANCE_RESPONSE.STATUS_CODE &&
            error.response?.data.status === MAINTENANCE_RESPONSE.STATUS &&
            error.response?.data.data.scope === MAINTENANCE_RESPONSE.SCOPE;

          const isSkipSentry =
            isMaintenance ||
            (error.message.includes("Network Error") && error.config.url?.endsWith("oidc/ba-payment/token")) ||
            error.message.includes("Both token and refresh token have expired. Your request was aborted.");

          if (isMaintenance) {
            this.transitionToMaintenance(error);
          }

          if (!isSkipSentry) {
            this.sendToSentry(error);
          }

          return Promise.reject(error);
        });
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  /**
   * メンテナンス中の場合は503エラーを返す
   *
   * ・AWSのALB(WAF)から金融機関ごとにメンテナンスモードが設定できます。（当該メンテナンス条件に該当する場合）
   *
   * ``` レスポンスの内容例(AWS側で設定)
   * {
   *  "status": "maintenance", // 固定
   *  "message": "現在メンテナンス中です。", // 固定
   *  "data": {
   *   "scope": "chanto", // 固定
   *   "start_date": "2024年 12月 23日 10:00 頃", // 任意の日付フォーマット
   *   "end_date": "2025年 01月 06日 8:00 頃" // 任意の日付フォーマット
   *  }
   * }
   * ```
   */
  private transitionToMaintenance(error: any): void {
    // TODO: navigateToを利用 or エラーとして対応できるよう修正
    window.$nuxt.$router.push({
      name: "maintenance",
      params: {
        isActive: "true",
        startDate: error.response?.data.data.start_date,
        endDate: error.response?.data.data.end_date
      }
    });
  }

  /**
   * Sentryへエラー通知
   */
  private sendToSentry(error: any) {
    // TODO: 3系アップデート後修正
    window.$nuxt.$sentry &&
      window.$nuxt.$sentry.captureException(error, {
        // 同じURLの場合、ステータスコードが違っても同じエラーと判定されるので`fingerprint`を変更して違うエラーと認識させている
        fingerprint: [error.response?.status, error.config?.url || ""]
      });
  }
}

export default ApiClient;
