import ApiClient from "./api-client";
import { ServicePlanEx } from "~/types/master";
import { APIResponse } from "~/types";

export class PaymentMasterApi {
  // -------------------------------------------------------
  // Properties
  // -------------------------------------------------------
  private readonly apiClient: ApiClient;

  // -------------------------------------------------------
  // Constructor
  // -------------------------------------------------------
  constructor() {
    this.apiClient = new ApiClient();
  }

  // -------------------------------------------------------
  // Methods
  // -------------------------------------------------------
  /**
   * プランマスタ一覧取得（拡張用）API
   */
  getMasterServicePlans(): Promise<APIResponse<ServicePlanEx>> {
    return this.apiClient.get<APIResponse<ServicePlanEx>>("/api/v1/master/service/plans").then((response) => {
      return response.data;
    });
  }

  /**
   *
   */
  getMasterServiceTermsCookie(): Promise<any> {
    return this.apiClient.get<any>("/api/v1/master/service/terms/cookie").then((response) => {
      return response.data;
    });
  }

  /**
   *
   */
  getMasterServiceTerm(bankCode: string): Promise<any> {
    return this.apiClient.get<any>(`/api/v1/master/service/terms/${bankCode}`).then((response) => {
      return response.data;
    });
  }

  /**
   *
   */
  getMasterServiceBankChantoterm(): Promise<any> {
    return this.apiClient.get<any>("/api/v1/master/service/bank/chantoterm").then((response) => {
      return response.data;
    });
  }
}
