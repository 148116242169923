import { defineStore } from "pinia";
import { reactive } from "@nuxtjs/composition-api";

export const useLoading = defineStore("loadingStore", () => {
  /**
   * Data
   */
  const state = reactive({
    isShowLoading: false
  });

  /**
   * Methods
   */
  const showLoading = () => {
    state.isShowLoading = true;
  };

  const hideLoading = () => {
    state.isShowLoading = false;
  };

  return {
    state,
    showLoading,
    hideLoading
  };
});
