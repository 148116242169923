import { reactive } from "@nuxtjs/composition-api";
import { defineStore } from "pinia";
import { IBaPaymentUser, IBaPaymentInfo, PlanInfo } from "@/types/ba-payment";
import { getBaPaymentMe, getBaPaymentInfo, getBaPlanInfo } from "@/api/user";
import { PLAN_ID, USER_ROLE } from "~/utils/constants";

const emptyUser: IBaPaymentUser = {
  user_unique_code: "",
  company_unique_code: "",
  role: 0,
  registration_status: 0,
  service_id: 0,
  plan_id: 0,
  use_start_date: "",
  use_end_date: "",
  user_id: 0,
  reference_id: 2,
  receive_reference_id: 1
};

const emptyPaymentInfo: IBaPaymentInfo = {
  plan_id: 0,
  join_date: "",
  start_date: "",
  end_date: "",
  withdrawal_date: null
};

const emptyPlanInfo: PlanInfo = {
  plan_id: 0,
  previous_plan_id: 0,
  plan_name: "",
  bank_code: "",
  join_date: "",
  start_date: "",
  end_date: "",
  withdrawal_date: "",
  is_contract: false,
  is_plan_changed: false,
  current_amount: 0,
  current_total_amount: 0,
  amount: 0,
  total_amount: 0,
  issue_amount: 0,
  issue_total_amount: 0,
  receive_amount: 0,
  receive_total_amount: 0,
  max_receive_files: 0,
  is_free_term: false,
  is_subscribe_cancellation: false,
  is_campaign: false,
  campaign_num_of_months: 0,
  campaign_start_date: "",
  campaign_finish_date: "",
  is_campaign_applied: false,
  applied_campaign_num_of_months: 0,
  applied_campaign_start_date: "",
  applied_campaign_finish_date: "",
  applied_campaign_end_date: ""
};

export const useBaPayment = defineStore("baPaymentStore", () => {
  /**
   * Data
   */
  const state = reactive({
    user: structuredClone(emptyUser),
    paymentInfo: structuredClone(emptyPaymentInfo),
    planInfo: structuredClone(emptyPlanInfo),
    isBaPaymentCompanyOwner: false,
    isBaPaymentCompanyUser: false,
    isBaPaymentInvalid: false,
    /** 有効なユーザーが存在するか */
    isExistPaymentUser: false,
    /** 受取型を契約しているかどうか */
    isSubscribedReceivePlan: false
  });

  /**
   * Methods
   */
  const _initStateUser = (user: IBaPaymentUser) => {
    state.user = user;
    state.isBaPaymentCompanyOwner = user.role === USER_ROLE.COMPANY_OWNER;
    state.isBaPaymentCompanyUser = user.role === USER_ROLE.COMPANY_USER;
    state.isBaPaymentInvalid = user.role === USER_ROLE.INVALID;
    state.isExistPaymentUser = user.company_unique_code !== "";
  };
  const _initStatePlanInfo = (planInfo: PlanInfo) => {
    const previousPlanId = planInfo.previous_plan_id;
    const planId = planInfo.plan_id;
    const issueReceivePlans: number[] = [
      PLAN_ID.ISSUE_RECEIVE_PLAN1,
      PLAN_ID.ISSUE_RECEIVE_PLAN2,
      PLAN_ID.ISSUE_RECEIVE_PLAN3
    ];

    state.planInfo = planInfo || state.planInfo;
    state.isSubscribedReceivePlan = issueReceivePlans.includes(previousPlanId) || issueReceivePlans.includes(planId);
  };

  const _setUser = (user: IBaPaymentUser) => {
    return Promise.all([user]).then(([user]) => {
      _initStateUser(user);

      return true;
    });
  };

  const loadBaPaymentUser = () => {
    const promiseUser = getBaPaymentMe();
    const promisePayment = getBaPaymentInfo();
    const promisePlanInfo = getBaPlanInfo();

    return Promise.all([promiseUser, promisePayment, promisePlanInfo]).then(([user, paymentInfo, planInfo]) => {
      if (user.data && Object.keys(user.data.data).length) {
        _setUser(user.data.data);
      } else {
        _setUser(emptyUser);
      }

      if (paymentInfo.data && paymentInfo.data.data.length) {
        state.paymentInfo = paymentInfo.data.data[0];
      } else {
        state.paymentInfo = emptyPaymentInfo;
      }

      _initStatePlanInfo(planInfo?.data?.data);

      return true;
    });
  };

  const loadBaPaymentPlan = () => {
    getBaPlanInfo().then((data) => {
      state.planInfo = data?.data?.data || state.planInfo;

      return true;
    });
  };

  return {
    state,
    loadBaPaymentUser,
    loadBaPaymentPlan
  };
});
