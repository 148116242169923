import { useBa } from "@/stores/use-ba";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ error }) {
  const { state: baState } = useBa();

  // BAのオーナー以外は404エラー
  if (!baState.isCompanyOwner) {
    error({ statusCode: 404 });
  }
}
