import ApiClient from "./api-client";

export class PaymentConstantsApi {
  // -------------------------------------------------------
  // Properties
  // -------------------------------------------------------
  private readonly apiClient: ApiClient;

  // -------------------------------------------------------
  // Constructor
  // -------------------------------------------------------
  constructor() {
    this.apiClient = new ApiClient();
  }

  // -------------------------------------------------------
  // Methods
  // -------------------------------------------------------
  /**
   *
   */
  getConstants(): Promise<any> {
    return this.apiClient.get<any>("/api/v1/constants").then((response) => {
      return response.data;
    });
  }
}
