import { useBa } from "@/stores/use-ba";
import { useBaPayment } from "@/stores/use-ba-payment";
import { registerAddPlanLink } from "~/utils/routes";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ error, redirect }) {
  const { state: baPaymentState } = useBaPayment();
  const { state: baState } = useBa();

  /**
   * 受取型の公開設定を確認する
   */
  const isPublicReceivePlan = () => {
    return baState.bank.is_enable_payment_receive;
  };

  // 1. 受取型の公開設定を確認する
  if (!isPublicReceivePlan()) {
    error({
      statusCode: 403,
      customMessage:
        `大変恐れ入りますが、ご契約の${baState.bank.ba_name}では現在「受取機能」はご利用いただけません。` +
        "\nお客様にはご不便をおかけいたしますが、何卒ご理解とご協力を賜りますようお願い申し上げます。"
    });
    return;
  }

  // 2. 受取型未契約の場合は契約画面へリダイレクトする
  if (!baPaymentState.isSubscribedReceivePlan) {
    redirect(registerAddPlanLink);
  }
}
