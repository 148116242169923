
import { defineComponent, useContext } from "@nuxtjs/composition-api";
import { computed } from "vue";
import NaviMenu from "./NaviMenu.vue";
import UserCard from "./UserCard.vue";
import { useBa } from "@/stores/use-ba";
import { useBaPayment } from "@/stores/use-ba-payment";
import { getPath as Path } from "@/utils/paths";
export interface MenuItem {
  title: string;
  icon: string;
  to: string;
  checkPath: string;
  onlyOwner: boolean;
  subMenuItems?: MenuItem[];
}

const menus: MenuItem[] = [
  {
    title: "TOP",
    icon: "mdi-home",
    to: Path("adminCompanies"),
    checkPath: "/admin/companies",
    onlyOwner: false
  }
];

/**
 * サイドナビルート
 */
export default defineComponent({
  name: "SideNavi",
  components: {
    NaviMenu,
    UserCard
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const context = useContext();
    const { state: baPaymentState } = useBaPayment();
    const { state: baState } = useBa();

    const menuItems = computed((): MenuItem[] => {
      if (baState.isSystemAdminZba) {
        return [
          ...menus,
          {
            title: "金融機関設定",
            icon: "mdi-cog",
            to: Path("adminBanks"),
            checkPath: "/admin/bank-setting",
            onlyOwner: false
          }
        ];
      }
      return menus;
    });

    const drawer = computed({
      get: () => props.value,
      set: (newValue) => emit("input", newValue)
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isErrorPage = (): boolean => context._errored;

    return {
      baState,
      baPaymentState,
      menuItems,
      drawer,
      isErrorPage
    };
  }
});
