
import { computed, defineComponent } from "vue";
import { useLoading } from "@/stores/use-loading";

export default defineComponent({
  setup() {
    /**
     * Composables
     */
    const { state, showLoading, hideLoading } = useLoading();

    /**
     * Computed
     */
    const isShow = computed(() => {
      return state.isShowLoading;
    });

    /**
     * Methods
     */
    const start = (): void => {
      showLoading();
    };
    const finish = (): void => {
      hideLoading();
    };

    return {
      isShow,
      start,
      finish
    };
  }
});
