import { useBa } from "@/stores/use-ba";

export default ({ app }) => {
  const { state: baState, loadBaUser } = useBa();

  if (process.client) {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        // eslint-disable-next-line prefer-const
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        // eslint-disable-next-line prefer-const
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        // eslint-disable-next-line prefer-const
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    })("bfe3cd0a-e60b-42ba-7fbf-8cf2569fc092");

    app.router.afterEach(async () => {
      if (!baState.user.ba_company_unique_code || !baState.user.ba_user_unique_code) await loadBaUser();
      if (
        typeof pendo !== "undefined" &&
        pendo.initialize &&
        baState.user.ba_company_unique_code &&
        baState.user.ba_user_unique_code
      ) {
        pendo.initialize({
          visitor: {
            id: baState.user.ba_user_unique_code
          },
          account: {
            id: baState.user.ba_company_unique_code
          }
        });
      }
    });
  }
};
