import { useBa } from "@/stores/use-ba";
import { useBaPayment } from "@/stores/use-ba-payment";
import { isUseValid } from "~/utils";
import { adminCompaniesListLink, registerLink } from "~/utils/routes";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function ({ redirect, error }) {
  const { state: baPaymentState } = useBaPayment();
  const { state: baState } = useBa();

  /** ミドルウェアの処理開始 **/
  // システム管理者、金融機関（事務局）、金融機関（支店）の場合は管理画面TOPにリダイレクト
  if (baState.isAdminUser) {
    redirect(adminCompaniesListLink);

    return;
  }

  // 有効なユーザーが存在しない、または、存在するが利用期間外の場合
  if (
    !baPaymentState.isExistPaymentUser ||
    !isUseValid(baPaymentState.user.use_start_date, baPaymentState.user.use_end_date)
  ) {
    // 企業オーナーなら新規登録画面へ
    if (baState.isCompanyOwner) {
      redirect(registerLink);

      return;
    }

    // ビジネスユーザーの場合はエラー
    if (baState.isCompanyUser) {
      error({ statusCode: 401 });
      console.error("利用登録されていないビジネスユーザのため、ご利用いただけません。");

      return; // eslint-disable-line
    }
  }
}
