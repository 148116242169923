import { defineStore } from "pinia";
import { reactive } from "@nuxtjs/composition-api";
import { PaymentConstantsApi } from "@/api/payment/payment-constants-api";
import { useLoading } from "@/stores/use-loading";

export const useConstants = defineStore("constantsStore", () => {
  /**
   * Composables
   */
  const { showLoading, hideLoading } = useLoading();

  /**
   * Data
   */
  const state = reactive({
    constants: null as Record<string, any> | null
  });

  /**
   * Methods
   */
  const initialize = async (isNoChache = false) => {
    const constants = localStorage.getItem("constants");

    // 定数の強制読み込み
    // constants.phpの値の追加・変更・削除が合った場合はVERSIONを変更して強制的に読み込ませる
    const isRefreshConstants =
      constants &&
      (!JSON.parse(constants).VERSION || (JSON.parse(constants).VERSION && JSON.parse(constants).VERSION !== "1.0.0"));

    if (isNoChache || !constants || isRefreshConstants) {
      await _getConstants();

      return;
    }

    state.constants = JSON.parse(constants);
  };

  const _refreshLocalStorageConstants = (constants: object) => {
    localStorage.removeItem("constants");

    localStorage.setItem("constants", JSON.stringify(constants));
  };

  /** Api */
  const paymentConstantsApi = new PaymentConstantsApi();

  const _getConstants = () => {
    showLoading();

    return paymentConstantsApi
      .getConstants()
      .then((response) => {
        state.constants = response.data;

        _refreshLocalStorageConstants(response.data);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return {
    state,
    initialize
  };
});
