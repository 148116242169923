import { useBaPayment } from "@/stores/use-ba-payment";
import { isPaymentEndDateValid, isUseValid } from "~/utils";
import { getBaCompany } from "@/api/user";
import { useBa } from "@/stores/use-ba";
import {
  changePermissionsLink,
  chantoTermLink,
  cookiePolicyLink,
  kokopelliIdTermLink,
  registerConfirmLink,
  registerLink,
  supportFaqLink,
  supportQaLink,
  topLink
} from "~/utils/routes";
import { BA_USER_TYPE, USER_ROLE } from "~/utils/constants";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default async function ({ $auth, error, route, redirect, $sentry }) {
  const { state: baPaymentState, loadBaPaymentUser } = useBaPayment();
  const { state: baState, loadBaUser } = useBa();

  /** ミドルウェアで使用する関数の定義 **/
  /**
   * エラー発生時、Sentryにイベント時の共通データをセットする
   * 仮にロギング処理内でエラーが発生しても、無視して通常処理を続行させるようにしています。
   */
  const setDimensionToSentry = () => {
    try {
      $sentry.setUser({
        ba_company_name: baState.user.ba_company_name,
        ba_company_type: baState.user.ba_company_type,
        ba_company_unique_code: baState.user.ba_company_unique_code,
        ba_user_type: baState.user.ba_user_type,
        ba_user_unique_code: baState.user.ba_user_unique_code,
        user_kind: "general"
      });
    } catch {}
  };

  /**
   * ユーザーのロールが適切かどうかを判定する
   * @param userType
   */
  const isValidUserType = () => {
    const validUserTypes = [
      BA_USER_TYPE.COMPANY_OWNER, // 企業オーナー
      BA_USER_TYPE.COMPANY_USER, // ビジネスユーザー
      BA_USER_TYPE.SYSTEM_ADMIN, // システム管理者
      BA_USER_TYPE.BANK_ADMIN, // 金融機関（事務局）
      BA_USER_TYPE.BRANCH_ADMIN, // 金融機関（支店）
      BA_USER_TYPE.BRANCH_USER // 金融機関（支店）ユーザー
    ];

    return validUserTypes.includes(baState.user.ba_user_type);
  };

  /**
   * 契約中かどうかを確認する
   */
  const isContract = () => {
    // TODO: plan_id = 0 を契約無しとして扱って良いか確認する
    if (baPaymentState.paymentInfo.plan_id === 0) {
      // まだba-paymentを使っていない状態ならば無課金状態
      return false;
    } else if (baPaymentState.paymentInfo.withdrawal_date) {
      // 退会日付が設定されていた場合は、課金終了日が今日以下であれば無課金状態とみなす。
      return isPaymentEndDateValid(baPaymentState.paymentInfo.end_date);
    }
    return true;
  };

  /**
   * 権限移譲を考慮してオーナーかどうかを確認する
   * NOTE:
   * 権限移譲してもbaStore.user.ba_user_typeは変更されないため
   * あえてgetBaCompany()で取得したデータを使用してオーナーかどうかを判定する。
   */
  const isOwnerWithTransferAuthority = async () => {
    const result = await getBaCompany();
    const baOwner = result.data.result.owner;

    return baState.user.ba_user_unique_code === baOwner.user_unique_code;
  };

  /**
   * BAのユーザータイプがちゃんと請求書に登録されたロールから変わったかどうかを確認する
   */
  const isRoleChanged = () => {
    // baのuser_typeをba-payment用に変換
    const baUserType = baState.isCompanyOwner ? USER_ROLE.COMPANY_OWNER : USER_ROLE.COMPANY_USER;

    // ちゃんと請求書のユーザーデータが存在する場合
    // BAとba-paymentでユーザーのロールに差異がある場合は権限変更ページへ
    return baUserType !== baPaymentState.user.role;
  };

  /**
   * ユーザーの有効期限確認処理の対象ページかどうかを判定する
   * @param path
   */
  const isPathToCheckUser = () => {
    // 除外対象ページ
    const excludedPages = [
      topLink,
      changePermissionsLink,
      registerLink,
      registerConfirmLink,
      chantoTermLink,
      kokopelliIdTermLink,
      supportFaqLink,
      supportQaLink,
      cookiePolicyLink
    ];
    // 除外対象ページ以外はチェック対象ページとする
    return !excludedPages.includes(route.path);
  };

  /**
   * ユーザーの入会日・退会日がセットされているかどうかを判定する
   * @param paymentUser
   */
  const isSetUseDate = () => {
    return baPaymentState.user.use_start_date && baPaymentState.user.use_end_date;
  };

  /** ミドルウェアの処理開始 **/
  // 1. ログインしていない場合はエラーにする
  if (!$auth.loggedIn) {
    error({ statusCode: 401 });
    return;
  }

  // TODO: <GlobalNotice />をapp.vueに配置できたら、useNoticeを使用して非表示
  // hideNotice();

  // 2. アクセストークンからBAユーザ情報を取得する
  await loadBaUser();

  // 3. エラー発生時にSentryへ常に送信する基本情報をセット
  setDimensionToSentry();

  // 4. クレジットカードが登録されていることを確認する
  if (!baState.user.is_credit_card) {
    error({ statusCode: 401 });
    console.error("BA側でクレジットカードが未登録です");
    return;
  }

  // 5. ユーザーのロールが適切であることを確認する
  if (!isValidUserType()) {
    error({ statusCode: 401 });
    console.error("ユーザーのロールが適切ではありません");
    return;
  }

  // 6. ちゃんと請求書のユーザー情報を取得する
  await loadBaPaymentUser();

  // 7. 権限変更ページの場合は以降のミドルウェアを抜ける
  if (route.path === changePermissionsLink) {
    return;
  }

  // 8. 契約中の場合はユーザーロールの確認を行う
  if (isContract()) {
    if (baPaymentState.isBaPaymentInvalid) {
      const isOwner = await isOwnerWithTransferAuthority();
      if (isOwner) {
        // 未登録のビジネスユーザーが企業オーナーへ権限変更された場合
        redirect(changePermissionsLink);
        return;
      }
      // 企業オーナーではないケース（通常起こり得ない）
      error({ statusCode: 401 });
      console.error("未登録のユーザーでユーザーのロールが適切ではありません");
      return;
    }

    // 登録済みユーザーが企業オーナーへ権限変更された場合
    if (isRoleChanged()) {
      // 権限に変更があれば権限変更ページへリダイレクトさせる
      redirect(changePermissionsLink);
      return;
    }
  }

  // 9. 確認が必要なページにおいて、ユーザーの入会日・退会日が適切かどうかを確認する
  if (isPathToCheckUser()) {
    if (!isSetUseDate()) {
      // 入会日・退会日がセットされていない場合はエラーにする
      error({ statusCode: 401 });
      console.error("未登録のユーザーです");
      return;
    }
    if (!isUseValid(baPaymentState.user.use_start_date, baPaymentState.user.use_end_date)) {
      // 利用期限外であればエラーにする
      error({ statusCode: 401, message: "利用期限が切れています" });
      console.error("利用期限が切れています");
      return; // eslint-disable-line
    }
  }
}
