
import { defineComponent } from "@nuxtjs/composition-api";
import { useConstants } from "@/stores/use-constants";
export default defineComponent({
  middleware: ["auth-general"],
  setup() {
    /**
     * Composables
     */
    const { initialize: initConstants } = useConstants();

    /**
     * Created
     */
    (async () => {
      await initConstants();
    })();
  }
});
